import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccessMediaFilterComponent } from '@app/shared/components/access-media-filter/access-media-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleModalModule } from 'ngx-simple-modal';
import { ChecklistItemComponent } from './components/checklist/checklist-item/checklist-item.component';
import { CheckListItemDirective } from './components/checklist/checklist-item/checklist-item.directive';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { TapDetailsComponent } from './components/tap-details/tap-details.component';
import { StatusLabelComponent } from './labels/status-label/status-label.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { OperatorValuePipe } from './pipes/operator-value.pipe';
import { SimpleInformationModalComponent } from './components/simple-information-modal/simple-information-modal.component';
import { SipsComponent } from './components/sips/sips.component';
import { SipsMessageComponent } from './components/sips/sips-message/sips-message.component';
import { TogglePasswordComponent } from './directive/toggle-password/toggle-password.component';
import { InputComponent } from './components/form/form-field/input.component';
import { InputCheckboxComponent } from './components/form/form-field-checkbox/input-checkbox.component';
import { FormFieldComponent } from './components/form/form-field/form-field.component';
import { LabelComponent } from './components/form/form-field/label/label.component';
import { ErrorComponent } from './components/form/form-field/error/error.component';
import { BtnLoadingComponent } from './components/button/btn-loading.component';
import { HelperComponent } from './components/helper/helper.component';
import { CaptionComponent } from './components/caption/caption.component';
import { FormFieldCheckboxComponent } from './components/form/form-field-checkbox/form-field-checkbox.component';
import { LabelCheckboxComponent } from './components/form/form-field-checkbox/label-checkbox/label-checkbox.component';
import { MessageComponent } from './components/message/message.component';
import { InternationalisationPluralPipe } from './pipes/i18nPlural.pipe';
import { RouterModule } from "@angular/router";
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { OgoneComponent } from './components/ogone/ogone.component';
import { OgoneMessageComponent } from './components/ogone/ogone-message/ogone-message.component';
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { HasJourneyFeeCapingReach } from './pipes/has-journey-fee-caping-reach.pipe';
import { HasJourneyEnded } from './pipes/has-journey-ended.pipe';
import { TranslateIfExistPipe } from './pipes/translate-if-exist';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    SimpleModalModule,
    RouterModule
  ],
  declarations: [
    TapDetailsComponent,
    EmptyListComponent,
    LocalDatePipe,
    ChecklistItemComponent,
    ChecklistComponent,
    CheckListItemDirective,
    StatusLabelComponent,
    AccessMediaFilterComponent,
    OrderByPipe,
    ConfirmComponent,
    OperatorValuePipe,
    SimpleInformationModalComponent,
    SipsComponent,
    SipsMessageComponent,
    TogglePasswordComponent,
    InputComponent,
    FormFieldComponent,
    LabelComponent,
    ErrorComponent,
    BtnLoadingComponent,
    HelperComponent,
    CaptionComponent,
    FormFieldCheckboxComponent,
    LabelCheckboxComponent,
    InputCheckboxComponent,
    MessageComponent,
    InternationalisationPluralPipe,
    LocalizedDatePipe,
    OgoneComponent,
    OgoneMessageComponent,
    MaintenanceBannerComponent,
    HasJourneyFeeCapingReach,
    HasJourneyEnded,
    TranslateIfExistPipe
  ],
  exports: [
    TapDetailsComponent,
    EmptyListComponent,
    LocalDatePipe,
    ChecklistItemComponent,
    ChecklistComponent,
    CheckListItemDirective,
    StatusLabelComponent,
    AccessMediaFilterComponent,
    OrderByPipe,
    ConfirmComponent,
    OperatorValuePipe,
    SipsComponent,
    SipsMessageComponent,
    TogglePasswordComponent,
    InputComponent,
    FormFieldComponent,
    LabelComponent,
    ErrorComponent,
    BtnLoadingComponent,
    HelperComponent,
    CaptionComponent,
    FormFieldCheckboxComponent,
    LabelCheckboxComponent,
    InputCheckboxComponent,
    MessageComponent,
    InternationalisationPluralPipe,
    LocalizedDatePipe,
    OgoneComponent,
    OgoneMessageComponent,
    MaintenanceBannerComponent,
    HasJourneyFeeCapingReach,
    HasJourneyEnded,
    TranslateIfExistPipe
  ],
  providers: [
    HasJourneyFeeCapingReach,
    HasJourneyEnded
  ]
})
export class SharedModule {}
